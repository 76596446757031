-
  name: "PulseLitecoin <br><small><em class='idea-phase'>Testing Phase</em></small>"
  description: "Dual mine PulseLitecoin and PulseBitcoin with ASIC token"
  isNew: true
  progress: 60
  url:

-
  name: Uniswap v3 Tools
  description: "View liquidity for pairs & positions"
  url: https://uni-v3.korkey.tech/

-
  name: PulseBitcoin Dashboard
  description: "Pulsebitcoin stats & alternative front-end. Also view future market supply & calculate mining profit over time."
  url: https://pulsebitcoin.korkey.tech/

-
  name: PulseDogecoin Carnival Attractions
  description: "Bulk manage & create PLSBLock NFTs"
  url: https://plsd-carnival.korkey.tech/

-
  name: PHUX Tools
  description: "Collection of tools to calculate IL & vePHUX boost for PHUX"
  url: https://phux-tools.korkey.tech/

